
import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import 'react-bulma-components/dist/react-bulma-components.min.css';
import {Container, Hero } from 'react-bulma-components';

import "./layout.css"

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
          <Hero size='fullheight'>
            <Hero.Body>
              <Container>
                {children}          
              </Container>
            </Hero.Body>
          </Hero>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
