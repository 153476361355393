import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import icon from '../images/app-icon.png'



function SEO({ description, lang, meta, keywords, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author,
            siteUrl
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const ogimage = `${site.siteMetadata.siteUrl}${icon}`;
  const d = new Date();

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },        
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : []
        )
        .concat(meta)}

        
    >
      <meta name="image"                                content={ogimage}></meta>
      <meta                         property="og:image" content={ogimage}></meta>
      <meta                         property="og:type"  content='website'></meta>
      <meta name="twitter:image"    property="twitter:image" content={icon}></meta>
      <meta name="twitter:card"     property="twitter:card" content='summary'></meta>
      <meta name="twitter:creator"  property="twitter:creator" content={site.siteMetadata.author}></meta>
      <meta                         property="og:url" content={site.siteMetadata.siteUrl}></meta>
      <meta                         property="fb:app_id" content='296315297715606'></meta>
      <meta name="description"      property="og:description" content={metaDescription}></meta>
      <meta name="author"                                     content={site.siteMetadata.author}></meta>
      <meta                         property="og:title" content={title}></meta>
      <meta name='date' content={d.toUTCString()}></meta>
      <meta name='robots' content='index,follow'></meta>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `pl`,
  meta: [],
  keywords: [],
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
}

export default SEO
